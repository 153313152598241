import React from 'react';
import { Trophy } from 'lucide-react';

const OktaAwardBanner = () => {
  return (
    <div className="award-banner" style={{ display: 'inline-flex', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px', height: '16px' }}>
        <Trophy style={{ height: '16px', width: '16px', color: '#022f6b', display: 'block' }} />
        <img
          src="okta_logo_black.png"
          alt="Okta"
          style={{ height: '16px', width: 'auto', display: 'block' }}
        />
      </div>
      <div className="divider" />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="text-gray-600 font-medium text-sm">SaaS Startup Competition Winner</span>
      </div>
    </div>
  );
};

export default OktaAwardBanner;
