import React from "react";
import { useTranslation } from 'react-i18next';
import { FaLinkedin, FaTwitter, FaGithub } from 'react-icons/fa';

function AboutUs() {
    const { t } = useTranslation();
    const teamMembers = [
        {
            name: "Justin Marciano",
            position: "CEO & Co-founder",
            image: "justin.png",
            linkedin: "https://linkedin.com/in/justin-marciano32/",
            twitter: "https://x.com/JustinMar32"
        },
        {
            name: "Paul Vann",
            position: "CTO & Co-founder",
            image: "paul.jpg",
            linkedin: "https://www.linkedin.com/in/paul-vann-b996b2120/"
        },
        {
            name: "Martin Roesch",
            position: "Advisor",
            image: "martin.jpg",
            linkedin: "https://www.linkedin.com/in/maroesch/"
        },
        {
            name: "Allison Miller",
            position: "Advisor",
            image: "allison.jpg",
            linkedin: "https://www.linkedin.com/in/allisonmiller/"
        },
        {
            name: "Jennifer Romeo",
            position: "Advisor",
            image: "jennifer.jpg",
            linkedin: "https://www.linkedin.com/in/jenniferromeo/"
        }
    ];
    return (
        <section id="company" className="team-section">
            <div className="about-us-display" >
                {/*<p className="faq-response">TODO: REPLACE BLURB WITH JUSTIN'S MATERIALS</p>*/}

                <h2>{t('meetOurTeam')}</h2>
                <div className="team-grid">
                    {teamMembers.map((member, index) => (
                        <div key={index} className="team-card">
                            <img data-src={member.image} alt={member.name} className="team-member-image" />
                            <h3>{member.name}</h3>
                            <p>{member.position}</p>
                            <div className="social-links">
                                <a href={member.linkedin} target="_blank" rel="noopener noreferrer" aria-label={`${member.name}'s LinkedIn`}>
                                    <FaLinkedin className="social-icon" />
                                </a>
                                {member.twitter && (
                                    <a href={member.twitter} target="_blank" rel="noopener noreferrer" aria-label={`${member.name}'s Twitter`}>
                                        <FaTwitter className="social-icon" />
                                    </a>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default AboutUs;
