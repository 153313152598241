import React from "react";
import { useTranslation } from 'react-i18next';

function ScrollingStats() {
    const { t } = useTranslation();
    return (
        <section className="key-stats">
            <h2>{t('whyShouldYouProtect')}</h2>
            <div className="stats-container">
                <div className="stats-scroll">
                    <div className="stat-card">
                        <div className="stat-number">90<span>%</span></div>
                        <p>{t('stat1')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">80<span>%</span></div>
                        <p>{t('stat2')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">36<span>%</span></div>
                        <p>{t('stat3')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">40<span>B</span></div>
                        <p>{t('stat4')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">26<span>%</span></div>
                        <p>{t('stat5')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">52<span>%</span></div>
                        <p>{t('stat6')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">{'<50'}<span>%</span></div>
                        <p>{t('stat7')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">52<span>%</span></div>
                        <p>{t('stat8')}</p>
                    </div>
                    {/* Duplicate cards for seamless looping */}
                    <div className="stat-card">
                        <div className="stat-number">90<span>%</span></div>
                        <p>{t('stat1')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">80<span>%</span></div>
                        <p>{t('stat2')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">36<span>%</span></div>
                        <p>{t('stat3')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">40<span>B</span></div>
                        <p>{t('stat4')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">26<span>%</span></div>
                        <p>{t('stat5')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">52<span>%</span></div>
                        <p>{t('stat6')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">{'<50'}<span>%</span></div>
                        <p>{t('stat7')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">52<span>%</span></div>
                        <p>{t('stat8')}</p>
                    </div>
                </div>
            </div>

            {/* New logos section */}
            <div className="stats-logos">
                <div className="stats-logo-container">
                    <img
                        src="c2pa_png.png"
                        alt="C2PA Logo"
                        className="stats-logo"
                    />

                </div>
                <div className="stats-logo-container">
                    <img
                        src="nvidia2.png"
                        alt="Nvidia Inception Program"
                        className="stats-logo"
                    />

                </div>
                <div className="stats-logo-container">
                    <img
                        src="soc.png"
                        alt="SOC Compliance"
                        className="stats-logo"
                    />
                    
                </div>
            </div>
        </section>
    );
}

export default ScrollingStats;
