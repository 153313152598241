import React, { useState, useRef } from 'react';
import { FaPlay, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const CaseStudySection = () => {
  const [activeStudy, setActiveStudy] = useState(null);
  const scrollRef = useRef(null);
  const { t } = useTranslation();

  const caseStudies = [
    {
      id: 1,
      title: "Facetime Deepfakes: The Attack Vector of the Future",
      description: "How we identified a method for displaying deepfake video and audio over Facetime",
      videoId: "iI8_mZBwdbA",
      thumbnail: "facetime.png"
    },
    {
      id: 2,
      title: "KnowBe4 Scam: How Validia Can Prevent Hiring Fraud",
      description: "Using Validia to stop insider threats.",
      videoId: "Qmbb2oevp1c",
      thumbnail: "knowbe4.png"
    },
    // Add more case studies as needed
  ];

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { current } = scrollRef;
      if (direction === 'left') {
        current.scrollBy({ left: -current.offsetWidth, behavior: 'smooth' });
      } else {
        current.scrollBy({ left: current.offsetWidth, behavior: 'smooth' });
      }
    }
  };

  return (
    <section className="case-study-section">
      <h2>{t('caseStudies')}</h2>
      <div className="case-study-carousel">
        <div className="case-study-container" ref={scrollRef}>
          {caseStudies.map(study => (
            <div
              key={study.id}
              className={`case-study-card ${activeStudy === study.id ? 'active' : ''}`}
              onClick={() => setActiveStudy(study.id)}
            >
              <div className="case-study-content">
                <h3>{study.title}</h3>
                <p>{study.description}</p>
                <div className="play-button">
                  <FaPlay />
                  <span>Watch Case Study</span>
                </div>
              </div>
              <div className="case-study-overlay" style={{ backgroundImage: `url(${study.thumbnail})` }}></div>
              {activeStudy === study.id && (
                <div className="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${study.videoId}?autoplay=1`}
                    title={study.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CaseStudySection;
