import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaTwitter, FaYoutube, FaInstagram, FaFacebook } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">Validia</div>
        <div className="footer-links">
          <a href="#product">{t('product')}</a>
          <a href="#company">{t('company')}</a>
          <a href="#testimonials">{t('customers')}</a>
          <a href="/blog">{t('blog')}</a>
          <a href="#contact">{t('contact')}</a>
          <a href="https://deepfake-digest-validia.beehiiv.com/subscribe">{t('newsletter')}</a>
          <a href='https://calendly.com/d/494-3xs-7yt/identifai-30-minute-meeting'>{t('bookDemo')}</a>
        </div>
        <div className="social-links">
          <a href="https://www.linkedin.com/company/validia-ai" target="_blank" rel="noopener noreferrer" aria-label="Validia LinkedIn"><FaLinkedin /></a>
          <a href="https://x.com/validia_" target="_blank" rel="noopener noreferrer" aria-label="Validia Twitter"><FaTwitter /></a>
          <a href="https://instagram.com/validia.ai/" target="_blank" rel="noopener noreferrer" aria-label="Validia Instagram"><FaInstagram /></a>
          <a href="https://www.youtube.com/channel/UCC_Rdk6PslebcvCKn43NuNQ" target="_blank" rel="noopener noreferrer" aria-label="Validia Youtube"><FaYoutube /></a>
          <a href="https://www.facebook.com/profile.php?id=61563382212780" target="_blank" rel="noopener noreferrer" aria-label="Validia Facebook"><FaFacebook /></a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>{t('copyright', { year: new Date().getFullYear() })}</p>
      </div>
    </footer>
  );
}

export default Footer;
