import React from "react";

export function ArcadeEmbed() {
  return (
    <div style={{ position: 'relative', paddingBottom: 'calc(50.028232636928294% + 41px)', height: 0, width: '100%' }}>
      <iframe
        src="https://demo.arcade.software/bZ6ULIS4cK9yhc59QKNg?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=true"
        title="Validia"
        frameBorder="0"
        loading="lazy"
        allowFullScreen
        allow="clipboard-write"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', colorScheme: 'light' }}
      />
    </div>
  )
}


export default ArcadeEmbed;
