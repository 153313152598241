import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const flags = {
  en: "🇺🇸",
  ja: "🇯🇵",
  es: "🇪🇸",
  zh: "🇨🇳"
};

const FlagLanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setIsOpen(false);
  };

  return (
    <div className="flag-language-selector">
      <button className="selected-flag" onClick={() => setIsOpen(!isOpen)}>
        {flags[i18n.language]}
      </button>
      {isOpen && (
        <div className="flag-options">
          {Object.entries(flags).map(([lang, flag]) => (
            <button key={lang} onClick={() => changeLanguage(lang)}>
              {flag}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default FlagLanguageSelector;
