import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import client from '../config/contentful';
import { Helmet } from 'react-helmet';

const styles = `
  .page-container {
    min-height: 150vh !important;
    padding: 40px 0 300px 0;
    position: relative;
    width: 100%;
  }

  .blog-post-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    text-align: left;
    display: none;
  }

  .blog-post-container.loaded {
    display: block;
    animation: fadeIn 0.8s ease-in forwards;
  }

  .loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    z-index: 1000;
  }

  .loading-spinner {
    width: 80px;
    height: 80px;
    border: 6px solid #eee;
    border-top: 6px solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  .loading-text {
    color: #000;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
    animation: pulse 1.5s ease-in-out infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes pulse {
    0%, 100% { opacity: 0.3; }
    50% { opacity: 1; }
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .back-link {
    display: inline-flex;
    align-items: center;
    color: #666;
    text-decoration: none;
    margin-bottom: 40px;
    font-size: 1.1rem;
  }

  .back-link:hover {
    color: #000;
  }

  .post-header {
    margin-bottom: 40px;
    text-align: left;
  }

  .post-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    line-height: 1.2;
  }

  .post-meta {
    color: #666;
    font-size: 1.1rem;
    margin-bottom: 30px;
  }

  .post-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 40px;
  }

  .post-content {
    line-height: 1.8;
    font-size: 1.1rem;
    color: #333;
    text-align: left;
  }

  .post-content p {
    margin-bottom: 20px;
    text-align: left;
  }

  .post-content h2 {
    font-size: 1.8rem;
    margin: 40px 0 20px;
    text-align: left;
  }

  .post-content h3 {
    font-size: 1.4rem;
    margin: 30px 0 15px;
    text-align: left;
  }

  .post-content img {
    width: 100%;
    height: auto;
    margin: 30px 0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }

  .post-content ul, .post-content ol {
    margin: 20px 0;
    padding-left: 20px;
    text-align: left;
  }

  .post-content li {
    margin-bottom: 10px;
  }

  .post-content blockquote {
    border-left: 4px solid #ddd;
    padding-left: 20px;
    margin: 30px 0;
    font-style: italic;
    color: #555;
  }

  .post-content a {
    color: #0066cc;
    text-decoration: none;
  }

  .post-content a:hover {
    text-decoration: underline;
  }

  .post-content-image {
    width: 100%;
    height: auto;
    margin: 30px 0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }

  @media (max-width: 768px) {
    .post-title {
      font-size: 2rem;
    }

    .post-content {
      font-size: 1rem;
    }
  }
`;

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, file } = node.data.target.fields;
        const imageUrl = file.url;
        return (
          <img
            src={imageUrl}
            alt={description || title || 'Blog image'}
            className="post-content-image"
          />
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.HEADING_1]: (node, children) => <h1 className="text-3xl font-bold mt-8 mb-4">{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2 className="text-2xl font-bold mt-8 mb-4">{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className="text-xl font-bold mt-6 mb-3">{children}</h3>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
      [BLOCKS.HR]: () => <hr className="my-8 border-t border-gray-300" />,
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.CODE]: text => <code className="bg-gray-100 px-2 py-1 rounded">{text}</code>,
    },
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        // For testing loading state
        await new Promise(resolve => setTimeout(resolve, 250));

        const response = await client.getEntries({
          content_type: 'blogPost',
          'fields.slug': slug,
          include: 10
        });

        if (!response.items.length) {
          navigate('/blog');
          return;
        }

        const postData = response.items[0];
        setPost({
          title: postData.fields.title,
          date: new Date(postData.sys.createdAt).toLocaleDateString(),
          readTime: postData.fields.readTime || '5 min read',
          image: postData.fields.coverImage?.fields?.file?.url,
          content: postData.fields.content
        });

        setTimeout(() => setLoading(false), 300);
      } catch (err) {
        setError('Failed to fetch blog post');
        console.error(err);
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug, navigate]);

  return (
    <>
      {post && (
        <Helmet>
          <title>{post.title}</title>
          <meta name="description" content={post.title}/>
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.title} />
          <meta property="og:url" content={`https://validia.ai/blog/${slug}`} />
          <meta property="og:image" content={post.image} />
          <link rel="canonical" href={`https://validia.ai/blog/${slug}`} />
          <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
          <link rel="apple-touch-icon" href={post.image} />
        </Helmet>
      )}
      
      <style>{styles}</style>
      <div className="page-container">
        {loading && (
          <div className="loading-container">
            <div className="loading-spinner" />
            <div className="loading-text">Loading Article</div>
          </div>
        )}

        <div className={`blog-post-container ${!loading ? 'loaded' : ''}`}>
          <Link to="/blog" className="back-link">← Back to Blog</Link>

          {error ? (
            <div>{error}</div>
          ) : post && (
            <article>
              <header className="post-header">
                <h1 className="post-title">{post.title}</h1>
                <div className="post-meta">
                  {post.date} • {post.readTime}
                </div>
              </header>

              {post.image && (
                <img
                  src={post.image}
                  alt={post.title}
                  className="post-image"
                />
              )}

              <div className="post-content">
                {documentToReactComponents(post.content, options)}
              </div>
            </article>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogPost;
